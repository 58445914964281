<template>
  <MainLayout>
    <div class="row cl-090D2E">
      <div class="mb-2 mb-md-3 col-12 ras-typo-big-title">
        Review your application, please.
      </div>

      <div class="col-12 d-flex justify-content-between align-items-center">
        <div class="col-6 pe-4">
          <v-select
            :options="userList"
            close-on-select
            placeholder="Choose ID"
            class="sw-select"
            searchable
            v-model="selectedId"
          />
        </div>

        <a
          class="col-6 cl-0D41FF curp"
          v-if="selectedId"
          @click="edit"
        >Edit</a>
      </div>
    </div>

    <template v-if="form && $store.state.route.params.id == selectedId">
      <div class="row my-5 flex-wrap">
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-10 col-md-5 mb-0">
            Residential address
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            First name
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Your first name"
                    v-model="form.aboutYou.first"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Last name
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Your first name"
                    v-model="form.aboutYou.last"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Residential address
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="sw-label-text">
                Country
              </div>
              <div class="d-flex swl-content">
                <v-select
                  close-on-select
                  :options="Object.keys(allowedCountries)"
                  class="sw-select w-100"
                  v-model="form.aboutYou.residenceAddr.country"
                  searchable
                  clear-on-select
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field" />
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="sw-label-text">
                State
              </div>
              <div class="d-flex swl-content">
                <v-select
                  close-on-select
                  :options="
                    form.aboutYou.residenceAddr.country
                      ? allowedCountries[form.aboutYou.residenceAddr.country]
                      : []
                  "
                  class="sw-select w-100"
                  v-model="form.aboutYou.residenceAddr.state"
                  searchable
                  clear-on-select
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field" />
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="sw-label-text">
                City
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Your first name"
                    v-model="form.aboutYou.residenceAddr.city"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field" />
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="sw-label-text">
                Postal code
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Your first name"
                    v-model="form.aboutYou.residenceAddr.postalCode"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field" />
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="sw-label-text">
                Full address
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Your first name"
                    v-model="form.aboutYou.residenceAddr.addr"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center col-12 pb-4">
          <div class="col-10">
            Different mailing address
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-1"
                name="switch-1"
                v-model="form.aboutYou.differentMailingAddr"
              >
              <label for="switch-1" />
            </div>
          </div>
        </div>

        <template v-if="form.aboutYou.differentMailingAddr">
          <div class="col-12 mb-3 d-flex flex-wrap mt-3">
            <div class="col-12 col-md-5 ras-typo-field">
              Mailing address
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Country
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    close-on-select
                    :options="Object.keys(allowedCountries)"
                    class="sw-select w-100"
                    v-model="form.aboutYou.mailing_addr.country"
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  State
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    close-on-select
                    :options="
                      form.aboutYou.mailing_addr.country
                        ? allowedCountries[form.aboutYou.mailing_addr.country]
                        : []
                    "
                    class="sw-select w-100"
                    v-model="form.aboutYou.mailing_addr.state"
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  City
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Your first name"
                      v-model="form.aboutYou.mailing_addr.city"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Postal code
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Your first name"
                      v-model="form.aboutYou.mailing_addr.postal_code"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Full address
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Your first name"
                      v-model="form.aboutYou.mailing_addr.addr"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            E-mail Address
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="E-mail address"
                    v-model="form.prefilledData.email"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Phone (Mobile)
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Phone number"
                    v-model="form.aboutYou.residenceAddr.phone"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Marital Status
          </div>
          <div class="col-12 col-md-7 d-flex">
            <div class="sw-radio me-5">
              <input
                type="radio"
                id="marital-status-radio-single"
                checked
                name="marital-status-radio"
                v-model="form.lifeSituation.maritalStatus"
                value="single"
              >
              <label for="marital-status-radio-single">Single</label>
            </div>
            <div class="sw-radio">
              <input
                type="radio"
                id="marital-status-radio-married"
                name="marital-status-radio"
                v-model="form.lifeSituation.maritalStatus"
                value="married"
              >
              <label for="marital-status-radio-married">Married</label>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Number Of Dependents
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Number Of Dependents"
                    v-model="form.lifeSituation.dependents"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Gender
          </div>
          <div class="col-12 col-md-7 d-flex">
            <div class="sw-radio me-5">
              <input
                type="radio"
                id="gender-status-radio-m"
                checked
                name="gender-status-radio"
                v-model="form.aboutYou.gender"
                value="M"
              >
              <label for="gender-status-radio-m">Male</label>
            </div>
            <div class="sw-radio">
              <input
                type="radio"
                id="gender-status-radio-f"
                name="gender-status-radio"
                v-model="form.aboutYou.gender"
                value="F"
              >
              <label for="gender-status-radio-f">Female</label>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Data Of Birth
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="01-12-1970"
                    v-maska="'##-##-####'"
                    v-model="form.aboutYou.dob"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 mb-3 d-flex flex-wrap mt-3"
          v-if="form.aboutYou.differentPlaceOfBirth"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Country Of Birth
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <v-select
                  close-on-select
                  :options="Object.keys(countryList)"
                  class="sw-select w-100"
                  v-model="
                    form.aboutYou.differentPlaceOfBirthDetails.placeOfBirth
                  "
                  searchable
                  clear-on-select
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 mb-3 d-flex flex-wrap mt-3"
          v-if="form.aboutYou.differentCitizenship"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Primary Citizenship
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <v-select
                  close-on-select
                  :options="Object.keys(countryList)"
                  class="sw-select w-100"
                  v-model="
                    form.aboutYou.differentCitizenshipDetails.citizenship
                  "
                  searchable
                  clear-on-select
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap mt-3">
          <div class="col-12 col-md-5 ras-typo-field">
            Employment
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <v-select
                  close-on-select
                  :options="employmentTypes"
                  class="sw-select w-100"
                  v-model="form.lifeSituation.employment"
                />
              </div>
            </div>
          </div>
        </div>

        <template
          v-if="
            ['Employed', 'Self-employed'].includes(
              form.lifeSituation.employment
            )
          "
        >
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Employer name
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter employer"
                      v-model="form.lifeSituation.employmentDetails.employer"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Nature of Employer's Business
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="d-flex swl-content">
                  <v-select
                    :options="Object.keys(businessOccupationList)"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="
                      form.lifeSituation.employmentDetails.employer_business
                    "
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Occupation
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="d-flex swl-content">
                  <v-select
                    :options="
                      form.lifeSituation.employmentDetails.employer_business
                        ? businessOccupationList[
                          form.lifeSituation.employmentDetails
                            .employer_business
                        ]
                        : []
                    "
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="form.lifeSituation.employmentDetails.occupation"
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Employer Address
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Country
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    close-on-select
                    :options="Object.keys(countryList)"
                    class="sw-select w-100"
                    v-model="
                      form.lifeSituation.employmentDetails.employer_address
                        .country
                    "
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  State
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    close-on-select
                    :options="
                      form.lifeSituation.employmentDetails.employer_address
                        .country
                        ? countryList[
                          form.lifeSituation.employmentDetails
                            .employer_address.country
                        ]
                        : []
                    "
                    class="sw-select w-100"
                    v-model="
                      form.lifeSituation.employmentDetails.employer_address
                        .state
                    "
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  City
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="City"
                      v-model="
                        form.lifeSituation.employmentDetails.employer_address
                          .city
                      "
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Postal code
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Postal code"
                      v-model="
                        form.lifeSituation.employmentDetails.employer_address
                          .postal_code
                      "
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field" />
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Full address
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Address"
                      v-model="form.lifeSituation.employmentDetails.addr"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Passport
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Passport"
                      v-model="form.aboutYou.passport_number"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="row my-5 flex-wrap">
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-10 col-md-5 mb-0">
            Financial Information
          </div>
          <div
            class="
              col-2 col-md-7
              d-flex
              justify-content-end justify-content-lg-start
            "
          />
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Annual Net Income in CHF
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Annual Net Income in CHF"
                    v-model="form.investmentProfile.annualNetIncome"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Net Worth in CHF
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Net Worth in CHF"
                    v-model="form.investmentProfile.illiquidNetWorth"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Liquid Net Worth in CHF
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Liquid Net Worth in CHF"
                    v-model="form.investmentProfile.liquidNetWorth"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="d-flex align-items-center col-12 pb-4">
            <div class="col-10">
              Please fill all categories that contribute to your net worth.
              Regulations require that we collect this information.
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-4"
                  name="switch-4"
                  v-model="form.lifeSituation.additionalIncome"
                >
                <label for="switch-4" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 mb-3 d-flex flex-wrap"
          v-if="form.lifeSituation.additionalIncome"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Sources of Wealth
          </div>
          <div class="col-12 col-md-7">
            <div
              class="sw-label mb-3"
              v-for="(item, i) in Object.keys(
                form.lifeSituation.additionalIncomeDetails
              )"
              :key="'sources-of-wealth-' + i"
            >
              <template v-if="item !== 'reason'">
                <div class="sw-label-text">
                  {{ addIncomeDetailsTitles[item] }}
                </div>
                <div class="d-flex swl-content">
                  <div
                    class="sw-number sw-number_percent w-100 sw-number_right"
                  >
                    <div
                      class="swn-percent"
                      style="right: 0.5rem"
                    >
                      %
                    </div>
                    <input
                      class="w-100 mw-100 text-right"
                      type="number"
                      min="0"
                      max="100"
                      :placeholder="addIncomeDetailsTitles[item]"
                      v-model="form.lifeSituation.additionalIncomeDetails[item]"
                    >
                  </div>
                </div>
              </template>

              <template
                v-if="
                  item == 'reason' &&
                    form.lifeSituation.additionalIncomeDetails.other > 0
                "
              >
                <div class="sw-label-text">
                  Reason
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Provide reason here"
                      v-model="
                        form.lifeSituation.additionalIncomeDetails.reason
                      "
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="d-flex align-items-center col-12 pb-4">
            <div class="col-10">
              Are you or anyone in your immediate family employed by or
              registered with a broker-dealer, investment advisor, futures
              commission merchant, hedge fund, exchange or other financial
              services firm (aka a "Financial Firm")?
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-7"
                  name="switch-7"
                  v-model="form.lifeSituation.regulatoryAffiliation"
                >
                <label for="switch-7" />
              </div>
            </div>
          </div>
        </div>

        <template v-if="form.lifeSituation.regulatoryAffiliation">
          <div
            class="col-12 d-flex flex-wrap mt-4 mb-4"
            v-for="(item, i) in form.lifeSituation.regulatoryAffiliationDetails"
            :key="'addInc' + i"
          >
            <div class="col-md-6 col-12 pe-md-2">
              <div class="sw-label">
                <div class="sw-label-text">
                  Relationts
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    :options="['Other', 'Spouse', 'Parent', 'Child']"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="item.relation"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 ps-md-2 mt-3 mt-md-auto">
              <div class="sw-label">
                <div class="sw-label-text">
                  Holder full name
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter holder full name"
                      v-model="
                        form.lifeSituation.regulatoryAffiliationDetails[i].name
                      "
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Company
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter company name"
                      v-model="item.company"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 pe-md-2 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Company country
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    :options="Object.keys(countryList)"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="item.country"
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 ps-md-2 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  State/Province
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    :options="item.country ? countryList[item.country] : []"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="item.state"
                    searchable
                    clear-on-select
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 pe-md-2 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Company city
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter company city"
                      v-model="item.city"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 ps-md-2 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Postal code
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter postal code"
                      v-model="item.postal_code"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Company full address
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter street address, building, office number, etc."
                      v-model="item.addr"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 pe-md-2 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Company phone
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter company phone"
                      v-model="item.phone"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 ps-md-2 mt-3">
              <div class="sw-label">
                <div class="sw-label-text">
                  Company e-mail
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Enter company e-mail"
                      v-model="item.email"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 mt-3 mb-45"
            @click="addRegAff"
          >
            <div class="d-flex align-items-center">
              <div class="swi plus-square swi_mini d-inline-block me-2" />
              Add one more affiliated person
            </div>
          </div>
        </template>

        <div class="col-12 mt-4">
          <div class="d-flex align-items-center col-12 pb-4">
            <div class="col-10">
              Are you a director, a 10% shareholder or a policy-making officer
              of any publicly traded company?
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-6"
                  name="switch-6"
                  v-model="form.lifeSituation.regulatoryOfficer"
                >
                <label for="switch-6" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 mb-3 d-flex flex-wrap"
          v-if="form.lifeSituation.regulatoryOfficer"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Stock symbols
          </div>
          <div class="col-12 col-md-7">
            <div class="sw-label">
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Stock symbols"
                    v-model="form.investmentProfile.regulatoryOfficerSymbols"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="d-flex align-items-center col-12 pb-4">
            <div class="col-10">
              I have never invested before
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-5"
                  name="switch-5"
                  v-model="form.lifeSituation.neverInvestBefore"
                >
                <label for="switch-5" />
              </div>
            </div>
          </div>
        </div>

        <template v-if="!form.lifeSituation.neverInvestBefore">
          <div class="col-12 col-md-5 ras-typo-field mb-3">
            Investment Experience
          </div>
          <div class="col-12 col-md-7" />
          <div
            class="col-12 mb-3 d-flex flex-wrap"
            v-for="item in form.lifeSituation.investmentExperienceDetails"
            :key="'exp-' + item.asset_class"
          >
            <div class="col-12 col-md-2 ras-typo-field">
              {{ item.asset_class }}
            </div>
            <div class="col-12 col-md-12 d-flex flex-wrap">
              <div class="col-md-4 col-12">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Years of experience
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="yearsOfExperience"
                      close-on-select
                      placeholder="0 Years of Experience"
                      class="sw-select"
                      v-model="item.years_trading"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12 px-md-2">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Trades per year
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="tradesPerDay"
                      close-on-select
                      placeholder="0 Trades per Year"
                      class="sw-select"
                      v-model="item.trades_per_year"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Knowledge level
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="knowledgeLevels"
                      close-on-select
                      placeholder="No Knowledge"
                      class="sw-select"
                      v-model="item.knowledge_level"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="row my-5 flex-wrap">
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-10 col-md-5 mb-0">
            Tax Information
          </div>
          <div
            class="
              col-2 col-md-7
              d-flex
              justify-content-end justify-content-lg-start
            "
          />
        </div>

        <div
          class="d-flex align-items-center col-12 pb-4"
          v-if="!form.aboutYou.tax_number"
        >
          <div class="col-10">
            I don’t have the Tax number of
            {{ form.aboutYou.residenceAddr.country }}
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-2"
                name="switch-2"
                v-model="form.aboutYou.dontHaveTax"
              >
              <label for="switch-2" />
            </div>
          </div>
        </div>

        <template v-if="form.aboutYou.dontHaveTax">
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Tax residency (None)
            </div>
            <div class="col-12 col-md-7">
              <div class="bordered-wrapper py-3 col-12">
                <div class="sw-radio col-12 mb-2 mb-md-0">
                  <input
                    type="radio"
                    id="1-radio"
                    name="example-radio"
                    v-model="form.aboutYou.dontHaveTaxDetails.option"
                    value="I have a US taxpayer identification number"
                  >
                  <label for="1-radio">
                    I have a US taxpayer identification number and I will add it
                    to the W8 tax form.
                  </label>
                </div>
              </div>

              <div class="bordered-wrapper py-3 col-12">
                <div class="sw-radio col-12 mb-2 mb-md-0">
                  <input
                    type="radio"
                    id="2-radio"
                    name="example-radio"
                    v-model="form.aboutYou.dontHaveTaxDetails.option"
                    value="Country does not issue TINs to its residents"
                  >
                  <label for="2-radio">
                    {{ form.aboutYou.residenceAddr.country }} does not issue
                    TINs to its residents.
                  </label>
                </div>
              </div>

              <div class="bordered-wrapper py-3 col-12">
                <div class="sw-radio col-12 mb-2 mb-md-0">
                  <input
                    type="radio"
                    id="3-radio"
                    name="example-radio"
                    v-model="form.aboutYou.dontHaveTaxDetails.option"
                    value="I am not legally required to obtain a TIN from country"
                  >
                  <label for="3-radio">
                    I am not legally required to obtain a TIN from
                    {{ form.aboutYou.residenceAddr.country }}
                  </label>
                </div>
              </div>
              <div class="bordered-wrapper py-3 col-12">
                <div class="sw-radio col-12 mb-2 mb-md-0">
                  <input
                    type="radio"
                    id="4-radio"
                    name="example-radio"
                    value="TINs are issued, however, account holder is exempt from this requirement under the laws of country"
                    v-model="form.aboutYou.dontHaveTaxDetails.option"
                  >
                  <label for="4-radio">
                    TINs are issued, however, account holder is exempt from this
                    requirement under the laws of
                    {{ form.aboutYou.residenceAddr.country }}
                  </label>
                </div>
              </div>
              <div class="bordered-wrapper py-3 pb-5 col-12">
                <div class="sw-radio col-12 mb-2 mb-md-0">
                  <input
                    type="radio"
                    id="5-radio"
                    name="example-radio"
                    value="other"
                    v-model="form.aboutYou.dontHaveTaxDetails.option"
                  >
                  <label for="5-radio"> Other </label>
                </div>
                <div
                  class="col-12 mt-4 sw-label"
                  v-if="form.aboutYou.dontHaveTaxDetails.option == 'other'"
                >
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Provide the reason here"
                        v-model="form.aboutYou.dontHaveTaxDetails.reason"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="!form.aboutYou.dontHaveTax">
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Tax residency
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Tax number"
                      v-model="form.aboutYou.tax_number"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div
          class="col-12 mt-4"
          v-if="
            !form.aboutYou.tax_number && form.aboutYou.residenceAddr.country
          "
        >
          <div
            class="d-flex align-items-center col-12 pb-4"
            :class="{
              'bordered-wrapper': !form.aboutYou.differentTaxResidency,
            }"
          >
            <div class="col-10">
              I have different Tax residency than
              {{ form.aboutYou.residenceAddr.country }}
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-3"
                  name="switch-3"
                  v-model="form.aboutYou.differentTaxResidency"
                >
                <label for="switch-3" />
              </div>
            </div>
          </div>
        </div>

        <template v-if="form.aboutYou.differentTaxResidency">
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Different tax residency
            </div>
            <div class="col-12 col-md-7">
              <div class="sw-label">
                <div class="sw-label-text">
                  Country
                </div>
                <div class="d-flex swl-content">
                  <v-select
                    :options="Object.keys(countryList)"
                    close-on-select
                    placeholder="No Knowledge"
                    class="sw-select"
                    v-model="form.aboutYou.differentTaxResidencyDetails.country"
                  />
                </div>
              </div>

              <div class="sw-label mt-3">
                <div class="sw-label-text">
                  Tax number
                </div>
                <div class="d-flex swl-content">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Tax number"
                      v-model="
                        form.aboutYou.differentTaxResidencyDetails.tax_number
                      "
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="col-12 col-md-auto mt-3">
          <div
            class="btn btn-primary col-12 mt-3 px-4 button-padding"
            @click="store"
          >
            Save
          </div>
          <div
            class="btn btn-primary col-12 mt-3 px-4 button-padding"
            @click="openIBAcc"
          >
            openIBAcc
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<style scoped lang="scss">
</style>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api";
import Cookies from "js-cookie";

export default {
  components: {
    MainLayout,
  },
  data: () => {
    return {
      form: null,
      date: new Date().toGMTString(),
      employmentTypes: [
        "Employed",
        "Retired",
        "Self-employed",
        "Unemployed",
        "Studying (Student/Intern)",
        "At-home Trader (No Other Occupation)",
      ],
      addIncomeDetailsTitles: {
        employment: "Income from Employment",
        allowance: "Allowance / Spousal Income",
        disability: "Disability / Severance / Unemployment",
        inheritance: "Inheritance/Gift",
        interest: "Interest / Dividend Income",
        market: "Market Trading Profits",
        pension: "Pension / Government Retirement benefit",
        other: "Other",
        property: "Property",
        reason: "Reason",
      },
      yearsOfExperience: [
        "0 Years of Experience",
        "1 Year of Experience",
        "2 Years of Experience",
        "3 Years of Experience",
        "4 Years of Experience",
        "5 Years of Experience",
        "6 - 10 Years of Experience",
        "> 10 Years of Experience",
      ],
      tradesPerDay: [
        "0 Trades per Year",
        "1 - 10 Trades per Year",
        "11 - 25 Trades per Year",
        "26 - 50 Trades per Year",
        "51 - 100 Trades per Year",
        "> 100 Trades per Year",
      ],
      knowledgeLevels: [
        "No Knowledge",
        "Limited Knowledge",
        "Good Knowledge",
        "Extensive Knowledge",
      ],
      userList: [],
      selectedId: null,
    };
  },
  computed: {
    ...mapGetters([
      "onbForm",
      "countryList",
      "allowedCountries",
      "businessOccupationList",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchCountries",
      "fetchAllowedCountries",
      "fetchBusinessOccupation",
      "storeForm",
      "fetchForm",
    ]),
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getNow() {
      this.date = new Date().toGMTString();
    },
    async store() {
      this.$store.state.form = this.form;
      await this.storeForm();
    },
    addRegAff() {
      this.form.lifeSituation.regulatoryAffiliationDetails.push({
        relation: null,
        name: "",
        company: "",
        addr: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        phone: null,
        email: null,
      });
    },
    async fetchUserList() {
      const res = await api.get("/done.json");
      this.userList = res.data;
    },
    async edit() {
      this.form = null;
      localStorage.setItem("id", this.selectedId);
      this.$router.replace({
        name: "Management",
        params: {
          id: this.selectedId,
        },
      });
      this.$route.params.id = this.selectedId;
      this.$store.commit("SET_ROUTE", this.$route);
      await this.fetchForm();
      this.form = this.onbForm;
    },
    async openIBAcc() {
      await api.post(
        "/openIBAcc/" + this.selectedId,
        JSON.stringify(this.form)
      );
    },
    checkAuth() {
      var auth = Cookies.get("auth") !== undefined;

     
      if (!auth) {
        this.$store.state.blur = true;
        this.$store.state.managerAuth = false;
      } else {
        this.$store.state.blur = false;
        this.$store.state.managerAuth = true;
      }
    },
  },
  async mounted() {
    this.checkAuth();
    await this.fetchUserList();
    await this.fetchCountries();
    await this.fetchAllowedCountries();
    await this.fetchBusinessOccupation();

    if (this.$route.params.id) {
      this.selectedId = this.$route.params.id;
      await this.fetchForm();
      this.form = this.onbForm;
    }
  },
};
</script>